import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLink,
  BlogLiterature,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog13/infografika.png';
import kidsChoiceOfToys from '../../../images/educational-corner/blog13/Djetetov-izbor-igracaka.png';
import fineMotorics from '../../../images/educational-corner/blog13/Fina-motorika.png';
import roughMotorics from '../../../images/educational-corner/blog13/Gruba-motorika.png';
import parentChildPlay from '../../../images/educational-corner/blog13/Igra-roditelja-i-djeteta.png';
import cognition from '../../../images/educational-corner/blog13/Kognicija.png';

const GameAsAKeyToChildsGrowth = () => {
  return (
    <PageNew
      title="KOKOLINGO - Igra - ključ rasta i razvoja djece"
      description="Igra je temeljna aktivnost u životu svakog djeteta te sastavni dio odrastanja. 
      Kroz igru dijete spoznaje sebe i svijet koji ga okružuje. 
      Ona je spontani i prirodni način istraživanja i razvoja praktičnih vještina."
      headline="Igra - ključ rasta i razvoja djece"
    >
      <Text as="p">
        Igra je temeljna aktivnost u životu svakog djeteta te sastavni dio
        odrastanja. Kroz igru dijete spoznaje sebe i svijet koji ga okružuje.
        Ona je spontani i prirodni način istraživanja i razvoja praktičnih
        vještina.
      </Text>
      <Text as="h2">Područja razvoja</Text>
      <Text as="p">
        U najranijim godinama života igra je bitna za razvoj motoričkih
        vještina, socioemocionalnih, kognitivnih, ali i komunikacijskih te
        jezično-govornih vještina.
      </Text>
      <Text as="p">
        <ul>
          <li>
            MOTORIKA
            <Text as="p">
              Vještine grube i fine motorike razvijaju se od ranog djetinjstva.{' '}
              <b>
                U grubu motoriku spadaju veći pokreti koje dijete čini svojim
                rukama, nogama ili cijelim tijelom.
              </b>{' '}
              Vještine grube motorike jesu hodanje, trčanje, skakanje, puzanje,
              penjanje itd. Za razliku od grube motorike,{' '}
              <b>
                fina motorika uključuje precizne pokrete i koordinaciju oko -
                ruka.
              </b>{' '}
              U vještine fine motorike spadaju pisanje, crtanje, zakopčavanje,
              rezanje itd. Grubu i finu motoriku dijete razvija kroz svakodnevne
              igre. Primjerice, igrom skrivača dijete razvija grubu motoriku jer
              mora trčati, puzati i saginjati se, a igrom s lutkom dijete će
              razvijati finu motoriku odijevanjem lutke, zakopčavanjem i
              pažljivim stavljanjem dodataka u njezine ruke. Budući da većina
              igara uključuje i određenu razinu tjelesne aktivnosti, to
              pozitivno utječe i na zdravlje djece, primjerice poboljšava
              kardiovaskularni sustav i sprječava prekomjernu težinu.
            </Text>
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={fineMotorics}
        imgAlt="Primjer fine motorike kod djece"
        imgTitle="Fina motorika"
      />
      <BlogImage
        imgSrc={roughMotorics}
        imgAlt="Primjer grube motorike kod djece"
        imgTitle="Gruba motorika"
      />
      <Text as="p">
        <ul>
          <li>
            SOCIOEMOCIONALNE VJEŠTINE
            <Text as="p">
              Tijekom igre djeca spoznaju sebe, ali i druge te grade odnose s
              njima. Igrajući se s drugima dijete će doživjeti mnoge emocije -
              radost, ponos, tugu, ljutnju, ljubomoru itd. Tako će učiti kako
              regulirati svoje emocije, ali i učiti o emocijama drugih, odnosno{' '}
              <b>razvijat će empatiju.</b> Igra je društvena aktivnost pa dijete
              uči kako uspostavljati kontakt s drugima, preuzima različite
              uloge, shvaća odnos “ja pa ti” te{' '}
              <b>što znači biti nekome prijatelj.</b>
            </Text>
          </li>
        </ul>
      </Text>
      <Text as="p">
        <ul>
          <li>
            KOGNICIJA
            <Text as="p">
              Već od najranije dobi dijete otkriva svijet oko sebe i razvija
              svoje mentalne sposobnosti. Dijete će često biti okruženo
              igračkama koje proizvode različite zvukove. Ono će vrlo brzo
              shvatiti da se njihovim protresanjem proizvodi zvuk. Kasnije{' '}
              <b>
                u igrama pretvaranja dijete razvija vještine kreativnosti,
                mišljenja i rješavanja problema.
              </b>{' '}
              Primjerice, dijete stavlja lonac na glavu i uzima štap - postaje
              kralj koji će riješiti problem u svojem kraljevstvu. Pokrpat će
              sve rupe na cesti, a cesta je samo malena stazica. Rupe će krpati
              blatom ili pijeskom koji nađe u blizini. Ovakvim igrama uči
              organizacijske vještine, generira različite ideje za rješavanje
              problema i što je najbitnije - zabavlja se.
            </Text>
          </li>
        </ul>
      </Text>
      <BlogImage
        imgSrc={cognition}
        imgAlt="Primjer kognicije kod djece"
        imgTitle="Kognicija"
      />
      <Text as="p">
        <ul>
          <li>
            KOMUNIKACIJA, JEZIK I GOVOR
            <Text as="p">
              Svaka igra koja se igra s drugima uključuje i neki oblik
              komunikacije.{' '}
              <b>
                Dijete mora znati kako pridobiti pažnju drugih, izraziti svoje
                želje i potrebe, ali i objasniti sama pravila igre.
              </b>{' '}
              Njome dijete razvija jezične vještine - pripovijeda, upotrebljava
              padeže, prijedloge i veznike, a samim time njegove rečenice
              postaju bogatije i dulje. Naravno, u većini slučajeva čini to
              koristeći se govorom.
            </Text>
          </li>
        </ul>
      </Text>
      <Text as="h2">Okolina</Text>
      <Text as="p">
        <b>Da bi se dijete moglo igrati, potrebna je podržavajuća okolina</b>{' '}
        koju čine roditelji ili skrbnici, bake i djedovi, odgojitelji, druga
        djeca i sve ostale osobe iz njegove okoline. Svi oni moraju biti spremni
        prihvatiti poziv na igru ili je sami potaknuti. Koliko će se djeca
        igrati ovisi i o dostupnosti materijala za igru - kockicama, lutkama,
        autićima, slikovnicama, bojicama itd. Sve to čini poticaj da se dijete
        krene igrati. Uz okolinu i dostupnost materijala, dijete treba imati i
        dovoljno vremena za igru. Ponekad je bolje osigurati djetetu vrijeme za
        igru nego ga upisati na još jednu aktivnost.
      </Text>
      <BlogImage
        imgSrc={parentChildPlay}
        imgAlt="Dijete se igra s roditeljom u podrzavajucoj okolini"
        imgTitle="Igra roditelja i djeteta"
      />
      <Text as="p">
        <b>
          Tijekom prvih dviju godina života dijete će uživati u senzomotornoj
          igri.
        </b>{' '}
        Otkrivat će svijet oko sebe tako što će gristi različite predmete,
        dodirivati ih, gledati i slušati. Istraživačka igra pozitivno utječe na
        kognitivni razvoj. Kasnije,{' '}
        <b>
          u razdoblju između druge i šeste godine, dijete će najviše vremena
          provoditi u igrama pretvaranja
        </b>
        , odnosno u simboličkoj igri. Primjerice, metla će biti konj ili će se
        dijete pretvarati da je ono doktor, a netko drugi pacijent. Praćenjem
        takvih igara vidimo koliko dijete usvaja jezik koji čuje jer će svoj
        jezični izričaj prilagoditi ulozi doktora i koristiti se pojmovima
        vezanim uz tu profesiju. Naravno, ove su igre vrlo bitne i za razvoj
        mašte.
      </Text>
      <Text as="p">
        <b>Svaka igra trebala bi biti slobodna.</b> To znači da dijete
        samostalno bira kada, gdje, s kim i s kojim će se sredstvima igrati. U
        takvoj igri dijete i odrasla osoba trebaju se međusobno uvažavati,
        slušati i uzajamno djelovati. Naravno, odrasla osoba može inicirati
        igru, a dijete će je prihvatiti ili ne. Ako nas nešto zabavlja, ne znači
        da će zabavljati i dijete te mu treba dopustiti mogućnost izbora - hoće
        li nam se pridružiti ili ne.
      </Text>
      <BlogImage
        imgSrc={kidsChoiceOfToys}
        imgAlt="Djetetov izbor sredstava ili igracaka"
        imgTitle="Djetetov izbor sredstava ili igracaka"
      />
      <Text as="p">
        Uključenost roditelja u igru dovodi do veće kompetentnosti djeteta i
        veće kreativnosti, što je važno za socijalni i kognitivni razvoj.
        Čitanje priča i igre uloga koje se nadovezuju na te priče potiče razvoj
        mašte i jezičnih vještina.{' '}
        <b>
          U igrama je poželjno da dijete vodi glavnu riječ, odnosno da vodi
          igru.
        </b>{' '}
        Tako ono uvježbava vještine donošenja odluka, rješavanja problema, uči
        dijeliti i pregovarati.
      </Text>
      <Text as="p">
        Ako roditelj ima pretjerano zaštitnički odnos prema djetetu, time
        smanjuje mogućnost spontane igre, što dovodi do mnogih neželjenih
        posljedica. Djeca će iz takvih odnosa izići pretjerano emocionalno
        ranjiva i osjetljiva, bit će manje kreativna i nefleksibilna u
        rješavanju problema, imat će manje socijalnih kompetencija, a
        neuključenost u igre koje zahtijevaju upotrebu grube motorike može
        dovesti i do pretilosti.{' '}
        <b>
          Djecu treba čuvati, ali im i dati dovoljno slobode da istražuju svijet
          kako bi se mogli razvijati.
        </b>
      </Text>
      <Text as="h2">Digitalno doba</Text>
      <Text as="p">
        S razvojem mnogih tehnologija današnje igre često se prebacuju u
        digitalno okružje. Od malih nogu djeca se koriste tabletima na kojima
        slušaju razne pjesmice ili igraju jednostavne igre. S ovakvim sadržajima
        treba biti oprezan jer će neki sadržaji biti poticajni za razvoj, a
        drugi ne. Ako dijete gleda određene pjesmice na YouTubeu, poželjno je da
        ti sadržaji budu na hrvatskom jeziku tako da dijete jača svoj materinski
        jezik.{' '}
        <b>
          Uz takve sadržaje roditelji i djeca mogu zajedno pjevati i glumiti,
          odnosno igrati igre pretvaranja.
        </b>{' '}
        Pjesmice i rime temelj su predvještina čitanja i pisanja.
      </Text>
      <Text as="p">
        <b>
          Igre na tabletima ili mobitelima mogu biti poticajne za razvoj fine
          motorike jer zahtijevaju precizne pokrete i koordinaciju oko - ruka.
        </b>{' '}
        Primjerice, koristeći se aplikacijom <BlogLink>Kokolingo</BlogLink>,
        osim što uvježbavaju pravilan izgovor, djeca u određenim aktivnostima
        razvijaju i vještine fine motorike. Kroz igru se najbolje uči, a djecu
        privlače digitalni sadržaji. Zbog toga je bitno odabrati one igre u
        digitalnom obliku s pomoću kojih će djeca doista razvijati svoje
        vještine.
      </Text>
      <Text as="p">
        Igra je najbitnija djelatnost u životu svakog djeteta. Ona je spontana
        aktivnost koja omogućava razvoj različitih vještina. S pomoću nje dijete
        gradi svoj identitet, razvija vještine snalažljivosti i kreativnosti,
        potiče samostalnost, ali i suradnju, razvija samokontrolu te mnoge druge
        vještine bitne za svakodnevni život. Svakom djetetu potreban je partner
        u igri i to je jedna od najbitnijih uloga koju svatko od nas treba
        veselo prihvatiti.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Klarin, M. (2017). <i>Psihologija dječje igre.</i> Zadar:
            Sveučilište u Zadru.
          </li>
          <li>
            Slunjski, E. i Ljubetić, M. (2014). Play and Its Pedagogical
            Potential in a Preschool Institution.{' '}
            <i>Croatian Journal of Education, 16</i>(Sp.Ed.1), 127-141.
          </li>
          <li>
            Stegelin, D.A. (2007). Zašto je važno zagovarati stav o važnosti
            igre na razvoj djeteta.{' '}
            <i>
              Dijete, vrtić, obitelj: Časopis za odgoj i naobrazbu predškolske
              djece namijenjen stručnjacima i roditeljima, 13
            </i>{' '}
            (47), 2-7.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default GameAsAKeyToChildsGrowth;
